// Set campaign details
if (!sessionStorage.hasOwnProperty('campaign_details_set')) {
  const urlParams = new URLSearchParams(document.location.search);

  const campaignDetails = {
    'landingPage': window.location.href || '',
    'referrer': document.referrer || '',
    'utmCampaign': urlParams.get('utm_campaign') || '',
    'utmContent': urlParams.get('utm_content') || '',
    'utmMedium': urlParams.get('utm_medium') || '',
    'utmSource': urlParams.get('utm_source') || '',
    'utmTerm': urlParams.get('utm_term') || '',
    'timestamp': new Date().toISOString(),
  };

  // Save to localStorage
  localStorage.setItem('campaign_details', JSON.stringify(campaignDetails));
  localStorage.setItem('campaign_details_updated_at', Date.now());

  // Add to campaign_history
  const campaignHistory = JSON.parse(localStorage.getItem('campaign_history') || '[]');
  campaignHistory.push(campaignDetails);
  localStorage.setItem('campaign_history', JSON.stringify(campaignHistory));

  // Save as first campaing details is applicable
  if (!localStorage.hasOwnProperty('first_campaign_details')) {
    localStorage.setItem('first_campaign_details', campaignDetails);
  }

  // Set a flag in the session storage so we can avoid calling this
  // routine more than once per session.
  sessionStorage.setItem('campaign_details_set', 'true');
}
